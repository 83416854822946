import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { API_ENDPOINTS } from 'constants/apiEndpoints';
import { callbackResolver } from 'helpers/callbackHelpers';
import * as Shapes from 'interfaces/signalsOrdersShapes';
import * as AdminShapes from '../../../interfaces/administrationShapes';
import { ReqValues } from '../../../helpers/signalOrdersHelpers';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';
import { LostPositionLoseDTO } from 'interfaces/signalsOrdersShapes';

export const getSignalsHelper = (
  queryObj: Shapes.GetSignalsQuery
): Promise<AxiosResponse<Shapes.GetSignalsResponse>> => {
  return axios.get(API_ENDPOINTS.SIGNALS, { params: queryObj });
};

export const getOrdersHelper = (queryObj: Shapes.GetOrdersQuery): Promise<AxiosResponse<Shapes.GetOrdersResponse>> => {
  return axios.get(API_ENDPOINTS.ORDERS, { params: queryObj });
};

export const cancelOrderHelper = (id: number): Promise<AxiosResponse<Shapes.GetOrdersResponse>> => {
  return axios.post(API_ENDPOINTS.CANCEL_ORDER(id));
};

export const cancelSignalHelper = (id: number): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.CANCEL_SIGNAL(id));
};

export const closeSignalMarketHelper = (id: number): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.CLOSE_SIGNAL_MARKET(id));
};

export const closeSignalMarketBulkHelper = (signals_ids: string[]): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.CLOSE_SIGNAL_MARKET_BULK, { signals_ids });
};

export const closeSignalMarketLostBulkHelper = (lostPositions: LostPositionLoseDTO[]): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.ADMINISTRATION_CLOSE_LOST_POSITIONS, lostPositions);
};

export const closeSignalLimitHelper = (id: number, coefficient: Shapes.LimitCoefficient): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.CLOSE_SIGNAL_LIMIT(id), coefficient);
};

export const closeSignalBestLimitHelper = (id: number): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.CLOSE_SIGNAL_BEST_LIMIT(id));
};

export const closeSignalBestLimitBulkHelper = (signals_ids: number[]): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.CLOSE_SIGNAL_BEST_LIMIT_BULK, { signals_ids });
};

export const exportSignalsHelper = (): Promise<AxiosResponse<{ task_id: string }>> => {
  return axios.get(API_ENDPOINTS.EXPORT_SIGNALS);
};

export const getSignalsProfileFilters = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.SIGNALS_PROFILE_FILTERS);
};
export const getSignalsDashboardProfileFilters = (filter?: string): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.SIGNALS_DASHBOARD_PROFILE_FILTERS(filter));
};

export const getSignalsPairFilters = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.SIGNALS_PAIR_FILTERS);
};

export const getSignalsDashboarPairFilters = (filter?: string): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.SIGNALS_DASHBOARD_PAIR_FILTERS(filter));
};

export const getAdministration = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.ACCOUNT_INFO);
};

export const updateAdministrationData = (id: number, reqData: AdminShapes.DepositFromValue): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.EDIT_EXCHANGE_ACCOUNT(id), reqData);
};

export const getAdministrationTableInfo = (reqParams: AdminShapes.AdministrationFilters): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.ADMINISTRATION, { params: reqParams });
};

export const getAdministrationLostPosition = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.ADMINISTRATION_LOST_POSITIONS);
};

export const startRecoveryId = (id: number): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.RECOVERY_EXIT(id));
};

export const skipCheckById = (
  id: number,
  skip_check_by_timer?: boolean,
  skip_check_by_distance?: boolean
): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.SKIP_CHECK_BY_ID(id), { skip_check_by_timer, skip_check_by_distance });
};

export const skipCheckBulk = (
  signals_ids: number[],
  skip_check_by_timer?: boolean,
  skip_check_by_distance?: boolean
): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.SKIP_CHECK_BULK, { signals_ids, skip_check_by_timer, skip_check_by_distance });
};

export const reducePosition = (id: number, percent: number): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.REDUCE_POSITION(id), { percent });
};

export const reducePositionBulk = (signals_ids: string[], percent: number): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.REDUCE_POSITION_BULK, { signals_ids, percent });
};

export const riskControl = (params: { show_hide: boolean }): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.RISK_CONTROL, { params });
};

export const updateRiskControl = (id: number, data: Shapes.RiskControlShape): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.UPDATE_RISK_CONTROL(id), data);
};

export const createRiskControl = (reqData: ReqValues): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.RISK_CONTROL, reqData);
};

export const exportSignalPolling = (taskId: string): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.EXPORT_SIGNALS_POLLING(taskId));
};

const TASK_SUCCESS = 'SUCCESS';
const POLLING_INTERVAL = 3000;

export const pollingSignals = (taskId: string, onSuccess: () => void): void => {
  const intervalId = setInterval(async () => {
    const { data } = await exportSignalPolling(taskId);

    if (data?.task_status === TASK_SUCCESS && data?.filename) {
      const fileName = `Signals report ${format(new Date(), 'dd-MM-yyyy')}`;
      saveAs(data.filename, `${fileName}.xlsx`);
      clearInterval(intervalId);
      callbackResolver(onSuccess);
    }
  }, POLLING_INTERVAL);
};
