import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import cn from 'classnames';
import s from './SearchableSelect.module.scss';
import { FormControl, FormHelperText, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { ArrowDropUpOutlined, ArrowDropDownOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import useDebounce from 'hooks/useDebounce';
import { SCROLL_THRESHOLD } from '../../../constants/commonConstants';

interface CustomizedSelectProps {
  // Required props
  id: string;
  options: Array<{ value: number | string; label: string }>;
  value: string | null | boolean;
  onChange: (e: UniversalChangeEvent) => void;

  // Optional props
  label?: string;
  errorMsg?: string | null;
  isError?: boolean;
  formControlClass?: string;
  defaultValue?: { value: number | string; label: string };
  onBlur?: (e: React.FocusEvent) => void;
  onClearReqError?: () => void;
  onReachEnd?: () => void;
}
type UniversalChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
interface SearchableSelectProps extends CustomizedSelectProps {
  onSearchChange?: (query: string) => void;
  resetFieldValue?: () => void;
}

export const useStyles = makeStyles({
  inputHeight: {
    '& .MuiOutlinedInput-input': {
      height: 22
    }
  },
  inputLabel: {
    top: '0.8vh',
    '&.MuiInputLabel-shrink': {
      top: 0
    }
  }
});

const SearchableSelect = ({
  options,
  value,
  label,
  id,
  isError = false,
  errorMsg = null,
  formControlClass = '',
  onChange,
  onReachEnd = () => void 0,
  onSearchChange,
  resetFieldValue,
  defaultValue
}: SearchableSelectProps): JSX.Element => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchQuery, setSearchQuery] = useState(defaultValue?.label || '');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastScrollHeight, setLastScrollHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  // Handle scrolling to detect when the user reaches the end of the menu
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = target;

    // Check if end of the list
    if (scrollTop + clientHeight >= scrollHeight - SCROLL_THRESHOLD && scrollHeight !== lastScrollHeight) {
      setLastScrollHeight(scrollHeight);
      onReachEnd();
    }
  };

  // Update the search query and open the menu
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setIsMenuOpen(true);
  };

  // Select an option and close the menu
  const handleOptionSelect = (value: string | number, label: string | number) => {
    onChange({ target: { name: id, value } } as UniversalChangeEvent);

    setSearchQuery(String(label));
    setIsMenuOpen(false);
  };

  // Open the menu and reset the search query
  const handleMenuOpen = () => {
    setSearchQuery('');
    setIsMenuOpen(true);
  };

  // Reset fields value if search query is cleared
  useEffect(() => {
    if (!searchQuery) {
      resetFieldValue?.();
    }
  }, [searchQuery]);

  //   Notify parent component of search query changes (debounced)
  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery, onSearchChange]);

  // Maintain scroll position when options change
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollTop = scrollPosition;
    }
  }, [options]);

  // Default value setting
  useEffect(() => {
    if (defaultValue && defaultValue.label !== searchQuery) {
      setSearchQuery(defaultValue.label);
    }
  }, [defaultValue]);

  // Close the menu if the user clicks outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      if (
        inputRef.current &&
        !inputRef.current.contains(target) &&
        !(target instanceof HTMLElement && target.closest(`.${s.menu}`))
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <FormControl
      variant="outlined"
      className={cn(s.formControl, { [s.inputValid]: !!value, [formControlClass]: formControlClass })}
      error={isError}
    >
      <TextField
        id={id}
        name={id}
        label={label}
        ref={inputRef}
        value={searchQuery}
        onChange={handleSearchChange}
        onClick={handleMenuOpen}
        variant="outlined"
        size="small"
        autoComplete="off"
        fullWidth
        className={s.fullHeightTextField}
        placeholder="..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={handleMenuOpen} style={{ cursor: 'pointer' }}>
              {isMenuOpen ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
            </InputAdornment>
          )
        }}
        InputLabelProps={{
          classes: {
            root: classes.inputLabel
          }
        }}
      />
      {isMenuOpen && (
        <div className={s.menu} onScroll={handleScroll}>
          {options.length > 0 ? (
            options.map(({ value, label }, index) => (
              <MenuItem
                key={`${value}-${label}-${index}`}
                value={value}
                onMouseDown={() => handleOptionSelect(value, label)}
              >
                {label}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No results</MenuItem>
          )}
        </div>
      )}

      {errorMsg && <FormHelperText>{errorMsg}</FormHelperText>}
    </FormControl>
  );
};

export default SearchableSelect;
