import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
import { strategyPairsSelector, strategyProfilesSelector } from 'redux/selectors';
import { getMMProfilesFilter } from 'redux/actions/mmProfilesActions';
import { MMExtendedShape } from 'interfaces/mmProfilesShapes';
import {
  initialOrderValues,
  KeyParametersFormFields,
  OrderManagementFields,
  ExitOptions,
  StrategyStepsIdx,
  TIMEFRAME,
  PRICE_DIRECTION,
  START_COEFFICIENT,
  TIMER_MINS,
  ATR_THRESHOLD,
  ATR_N_PERIODS,
  ATR_SMOOTH_ALGO,
  SIGNAL_DUR_MINS,
  IS_ATR_MINUS_1,
  ATR_MINUS_1_TYPE,
  ATR_MINUS_1_VALUE,
  ATR_MINUS_2_TYPE,
  ATR_MINUS_2_VALUE,
  ENABLE_SEC_ATR,
  REVERT_THRESHOLD,
  TR_IN_ATR_THRESHOLD
} from 'constants/strategyProfilesAddConstants';
import {
  AddSPErrors,
  CreateProfileReqData,
  OrderManagementSettings,
  ProfilesSettingsValues,
  KeyParametersShape,
  StrategyProfileShape,
  AtrMinus1Types,
  PairsShape,
  StrategyProfilesShape,
  PriceDirectionsTypes,
  AtrSmoothAlgoTypes
} from 'interfaces/strategyProfilesShapes';
import { getProfileTemplates, getStrategiesTickers } from '../redux/actions/strategyProfilesActions';
import { SignalTimeframes } from '../constants/signalsOrdersConstants';

interface UseProfilesSettingsDataShape {
  mmProfiles: MMExtendedShape[];
  tickers: PairsShape[];
  profiles: StrategyProfilesShape[];
  searchTemplate: string;
  searchMM: string;
}

export const useProfilesSettingsData = (
  search: { searchTemplate: string; searchMM: string },
  onSetError: (err, type: string) => void
): UseProfilesSettingsDataShape => {
  const dispatch = useDispatch();
  const [mmProfiles, setMMprofiles] = useState([]);
  const tickers = useSelector(strategyPairsSelector);
  const profiles = useSelector(strategyProfilesSelector);

  const { searchTemplate, searchMM } = search;

  useEffect(() => {
    dispatch(
      getMMProfilesFilter(
        { searchMM },
        (data) => setMMprofiles(data),
        (err) => onSetError(err, strategyTypes.SET_MM_PROFILES_ERR)
      )
    );
  }, [dispatch, onSetError, searchMM]);

  useEffect(() => {
    dispatch(getStrategiesTickers({}));
  }, [dispatch, onSetError]);

  useEffect(() => {
    dispatch(getProfileTemplates(searchTemplate));
  }, [dispatch, onSetError, searchTemplate]);

  return { mmProfiles, tickers, profiles, searchTemplate, searchMM };
};

export const getOptionsArray = (
  rowOptions: Array<{ id: number; title: string }>
): { value: number; label: string }[] => {
  return rowOptions.reduce((acc, { id, title }) => [...acc, { value: id, label: title }], []);
};

export const getCreateProfileReqData = ({
  title,
  mmProfile,
  tickers,
  template
}: ProfilesSettingsValues): CreateProfileReqData => ({
  title,
  tickers: +tickers,
  mm_profile: +mmProfile,
  template: template ? +template : null
});

export const INITIAL_ERRORS = {
  mmProfilesErr: null,
  tradingPairsErr: null,
  profileErr: null,
  settingsErr: null,
  submitSummaryErr: null
};

export const strategyPageInitial: StrategyPageStateShape = {
  activeStep: StrategyStepsIdx.PROFILE_SETTINGS,
  errors: INITIAL_ERRORS,
  isLoading: false
};

export const strategyTypes = {
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_TRADING_PAIRS_ERR: 'SET_TRADING_PAIRS_ERR',
  SET_MM_PROFILES_ERR: 'SET_MM_PROFILES_ERR',
  SET_PROFILE_ERR: 'SET_PROFILE_ERR',
  SET_SETTINGS_ERR: 'SET_SETTINGS_ERR',
  SET_SUBMIT_SUMMARY_ERR: 'SET_SUBMIT_SUMMARY_ERR',
  CLEAR_ERRORS: 'CLEAR_ERRORS'
};

interface StrategyPageStateShape {
  activeStep: number;
  errors: AddSPErrors;
  isLoading: boolean;
}

export const getInitialStep = (data: StrategyPageStateShape, profileId?: number): StrategyPageStateShape => {
  return profileId ? { ...data, isLoading: true } : data;
};

export const strategyPageReducer = (
  state: StrategyPageStateShape,
  { type, payload }: { type: string; payload? }
): StrategyPageStateShape => {
  switch (type) {
    case strategyTypes.NEXT_STEP:
      return { ...state, activeStep: state.activeStep + 1 };

    case strategyTypes.PREVIOUS_STEP:
      return { ...state, activeStep: state.activeStep - 1 };

    case strategyTypes.SET_CURRENT_STEP:
      return { ...state, activeStep: payload, isLoading: false };

    case strategyTypes.SET_MM_PROFILES_ERR:
      return { ...state, errors: { ...state.errors, mmProfilesErr: payload } };

    case strategyTypes.SET_TRADING_PAIRS_ERR:
      return { ...state, errors: { ...state.errors, tradingPairsErr: payload } };

    case strategyTypes.SET_PROFILE_ERR:
      return { ...state, errors: { ...state.errors, profileErr: payload } };

    case strategyTypes.SET_SETTINGS_ERR:
      return { ...state, errors: { ...state.errors, settingsErr: payload } };

    case strategyTypes.SET_SUBMIT_SUMMARY_ERR:
      return { ...state, errors: { ...state.errors, submitSummaryErr: payload } };

    case strategyTypes.CLEAR_ERRORS:
      return { ...state, errors: INITIAL_ERRORS };

    default:
      return state;
  }
};

const getAtrValue = (
  enable_sec_atr: boolean,
  atr_minus_1_value: boolean,
  frValue: string,
  secValue: string
): string => {
  if (!atr_minus_1_value) {
    return '0';
  }
  return enable_sec_atr ? `${frValue};${secValue}` : frValue;
};

const getAtrType = (enable_sec_atr: boolean, atr_minus_1_value: boolean, atr_minus_1_type: AtrMinus1Types) => {
  if (!atr_minus_1_value) {
    return AtrMinus1Types.GTE;
  }
  return enable_sec_atr ? AtrMinus1Types.RNG : atr_minus_1_type;
};

export const getKeyParamsReqValues = ({
  atr_n_periods,
  atr_threshold_percents,
  start_point_coefficient,
  timer_minutes,
  signal_duration_minutes,
  ...rest
}: KeyParametersFormFields): KeyParametersShape => {
  const {
    atr_minus_2_value,
    atr_minus_2_type,
    atr_minus_1_value,
    atr_minus_1_type,
    revert_threshold,
    tr_in_atr_threshold,
    ...restProps
  } = rest;
  const artValue = getAtrValue(rest.enable_sec_atr, rest.is_atr_minus_1, atr_minus_1_value, atr_minus_2_value);
  const artType = getAtrType(rest.enable_sec_atr, rest.is_atr_minus_1, atr_minus_1_type);
  return {
    atr_n_periods: +atr_n_periods,
    atr_threshold_percents: +atr_threshold_percents,
    start_point_coefficient: +start_point_coefficient,
    timer_minutes: +timer_minutes,
    signal_duration_minutes: +signal_duration_minutes,
    atr_minus_1_value: artValue,
    atr_minus_1_type: artType,
    revert_threshold: revert_threshold ? +revert_threshold : null,
    tr_in_atr_threshold: tr_in_atr_threshold ? +tr_in_atr_threshold : null,
    ...restProps
  };
};

export const getInitialKeyParametersValues = (profile: StrategyProfileShape | null): KeyParametersFormFields => ({
  [TIMEFRAME]: profile?.timeframe ?? ('' as SignalTimeframes),
  [PRICE_DIRECTION]: profile?.settings?.signal_settings?.price_direction ?? ('' as PriceDirectionsTypes),
  [START_COEFFICIENT]: profile?.settings?.signal_settings?.start_point_coefficient
    ? profile?.settings?.signal_settings?.start_point_coefficient.toString()
    : '',
  [TIMER_MINS]: profile?.settings?.signal_settings?.timer_minutes
    ? profile?.settings?.signal_settings?.timer_minutes.toString()
    : '',
  [ATR_THRESHOLD]: profile?.settings?.signal_settings?.atr_threshold_percents
    ? profile?.settings?.signal_settings?.atr_threshold_percents.toString()
    : '',
  [ATR_N_PERIODS]: profile?.settings?.signal_settings?.atr_n_periods
    ? profile?.settings?.signal_settings?.atr_n_periods.toString()
    : '',
  [ATR_SMOOTH_ALGO]: profile?.settings?.signal_settings?.atr_smooth_algo ?? ('' as AtrSmoothAlgoTypes),
  [SIGNAL_DUR_MINS]: profile?.settings?.signal_settings?.signal_duration_minutes
    ? profile?.settings?.signal_settings?.signal_duration_minutes.toString()
    : '',
  [IS_ATR_MINUS_1]: profile?.settings?.signal_settings?.is_atr_minus_1 ?? false,
  [ATR_MINUS_1_TYPE]:
    profile?.settings?.signal_settings?.atr_minus_1_type === AtrMinus1Types.RNG
      ? AtrMinus1Types.GTE
      : profile?.settings?.signal_settings?.atr_minus_1_type ?? ('' as AtrMinus1Types),
  [ATR_MINUS_1_VALUE]:
    profile?.settings?.signal_settings?.atr_minus_1_type === AtrMinus1Types.RNG
      ? profile?.settings?.signal_settings?.atr_minus_1_value.split(';')[0].toString()
      : profile?.settings?.signal_settings?.atr_minus_1_value ?? '',
  [ATR_MINUS_2_TYPE]: AtrMinus1Types.LTE,
  [ATR_MINUS_2_VALUE]:
    profile?.settings?.signal_settings?.atr_minus_1_type === AtrMinus1Types.RNG
      ? profile?.settings?.signal_settings?.atr_minus_1_value.split(';')[1].toString()
      : '',
  [ENABLE_SEC_ATR]: profile?.settings?.signal_settings?.enable_sec_atr ?? false,
  [REVERT_THRESHOLD]: profile?.settings?.signal_settings?.revert_threshold?.toString() ?? '',
  [TR_IN_ATR_THRESHOLD]: profile?.settings?.signal_settings?.tr_in_atr_threshold?.toString() ?? ''
});

export const getOrderManagementReqValues = ({
  future_price_coefficient,
  atr_timer,
  change_of_atr_percentage,
  exit_order_place_coefficient,
  size_timer_minutes,
  high_low_change_percentage,
  high_low_change_timer,
  enter_orders,
  exists_distance_time,
  exit_timer_coeff_settings,
  exit_d_threshold_settings
}: OrderManagementFields): OrderManagementSettings => {
  return {
    is_exit_rule_by_time: ExitOptions.BY_TIME === exists_distance_time,
    is_exit_rule_by_distance: ExitOptions.BY_DISTANCE === exists_distance_time,
    is_exit_rule_by_time_and_distance: ExitOptions.BY_TIME_AND_DISTANCE === exists_distance_time,
    enter_orders: enter_orders.map(({ atr_distance_coefficient, size_percent, timer_minutes }) => ({
      atr_distance_coefficient: +atr_distance_coefficient,
      size_percent: +size_percent,
      timer_minutes: +timer_minutes
    })),
    exit_orders: {
      atr_timer: +atr_timer,
      change_of_atr_percentage: +change_of_atr_percentage,
      exit_order_place_coefficient: +exit_order_place_coefficient,
      size_timer_minutes: +size_timer_minutes,
      high_low_change_percentage: +high_low_change_percentage,
      high_low_change_timer: +high_low_change_timer
    },
    ...(future_price_coefficient !== '' && { future_price_coefficient: +future_price_coefficient }),
    ...((exists_distance_time === ExitOptions.BY_TIME || exists_distance_time === ExitOptions.BY_TIME_AND_DISTANCE) && {
      exit_timer_coeff_settings: exit_timer_coeff_settings.map(
        ({ change_exit_timer_minutes, change_exit_coefficient_by_time }) => ({
          change_exit_timer_minutes: +change_exit_timer_minutes,
          change_exit_coefficient_by_time: +change_exit_coefficient_by_time
        })
      )
    }),
    ...((exists_distance_time === ExitOptions.BY_DISTANCE ||
      exists_distance_time === ExitOptions.BY_TIME_AND_DISTANCE) && {
      exit_d_threshold_settings: exit_d_threshold_settings.map(
        ({ change_exit_d_threshold_by_distance, change_exit_coefficient_by_distance }) => ({
          change_exit_d_threshold_by_distance: +change_exit_d_threshold_by_distance,
          change_exit_coefficient_by_distance: +change_exit_coefficient_by_distance
        })
      )
    })
  };
};

export const getCurrentDraftStep = ({ mm_profile, pairs, title, settings }: StrategyProfileShape): StrategyStepsIdx => {
  const isProfileSettings = !isEmpty(pairs) && !isNil(mm_profile) && !!title;
  const isSignalSettings = !isNil(settings?.signal_settings);
  const isOrdersSettings = !isNil(settings?.order_management_settings);
  switch (true) {
    case isProfileSettings && isSignalSettings && isOrdersSettings:
      return StrategyStepsIdx.SUMMARY;
    case isProfileSettings && isSignalSettings:
      return StrategyStepsIdx.ORDER_MANAGEMENT;
    case isProfileSettings:
      return StrategyStepsIdx.KEY_PARAMETERS;
    default:
      return StrategyStepsIdx.PROFILE_SETTINGS;
  }
};

export const getInitialOrderValues = (profile: StrategyProfileShape | null): OrderManagementFields => {
  if (!profile?.settings?.order_management_settings) return initialOrderValues;
  const {
    settings: {
      order_management_settings: {
        exit_timer_coeff_settings,
        future_price_coefficient,
        enter_orders,
        exit_d_threshold_settings,
        is_exit_rule_by_distance,
        is_exit_rule_by_time,
        is_exit_rule_by_time_and_distance,
        exit_orders: {
          atr_timer,
          change_of_atr_percentage,
          exit_order_place_coefficient,
          size_timer_minutes,
          high_low_change_percentage,
          high_low_change_timer
        }
      }
    }
  } = profile;

  let exists_distance_time = ExitOptions.UNSET;
  if (is_exit_rule_by_distance) {
    exists_distance_time = ExitOptions.BY_DISTANCE;
  }
  if (is_exit_rule_by_time) {
    exists_distance_time = ExitOptions.BY_TIME;
  }
  if (is_exit_rule_by_time_and_distance) {
    exists_distance_time = ExitOptions.BY_TIME_AND_DISTANCE;
  }
  return {
    future_price_coefficient: future_price_coefficient?.toString() ?? '',
    atr_timer: atr_timer.toString(),
    change_of_atr_percentage: change_of_atr_percentage.toString(),
    orders_quantity: enter_orders.length.toString(),
    enter_orders: enter_orders.map(({ atr_distance_coefficient, timer_minutes, size_percent }) => ({
      atr_distance_coefficient: atr_distance_coefficient.toString(),
      timer_minutes: timer_minutes.toString(),
      size_percent: size_percent.toString()
    })),
    exit_order_place_coefficient: exit_order_place_coefficient.toString(),
    size_timer_minutes: size_timer_minutes.toString(),
    high_low_change_percentage: high_low_change_percentage.toString(),
    high_low_change_timer: high_low_change_timer.toString(),
    exit_timer_coeff_settings:
      exit_timer_coeff_settings?.map(({ change_exit_coefficient_by_time, change_exit_timer_minutes }) => ({
        change_exit_coefficient_by_time: change_exit_coefficient_by_time.toString(),
        change_exit_timer_minutes: change_exit_timer_minutes.toString()
      })) || [],
    exit_d_threshold_settings:
      exit_d_threshold_settings?.map(
        ({ change_exit_d_threshold_by_distance, change_exit_coefficient_by_distance }) => ({
          change_exit_d_threshold_by_distance: change_exit_d_threshold_by_distance.toString(),
          change_exit_coefficient_by_distance: change_exit_coefficient_by_distance.toString()
        })
      ) || [],
    exists_distance_quantity: exit_d_threshold_settings?.length.toString() ?? '',
    exists_time_quantity: exit_timer_coeff_settings?.length.toString() ?? '',
    exists_distance_time
  };
};
