import { call, put, takeLatest, ForkEffect } from 'redux-saga/effects';
import * as apiHelpers from './apiHelpers/logsApiHelper';
import * as actions from '../actions/logsActions';
import * as Shapes from 'interfaces/logsShapes';
import { callbackResolver } from 'helpers/callbackHelpers';
import { logsTypes } from '../types';
import { buildQueryString } from 'helpers/buildQueryString';

function* getLogsWorker({ payload }: Shapes.GetLogsShape) {
  try {
    const { data } = yield call(apiHelpers.getLogsHelper, payload.queryObj);
    yield put(actions.putLogs(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('getLogsWorker', error);
  }
}

function* getLogsFiltersWorker({ payload }: Shapes.GetLogsFiltersShape) {
  const { pair, profile } = payload.reqData;

  const queryString = buildQueryString({ pair, profile });
  try {
    const { data } = yield call(apiHelpers.getLogsFiltersHelper, queryString);
    yield put(actions.putLogsFilters(data));

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('getLogsFiltersWorker', error);
  }
}

function* getLogsCountWorker({ payload }: Shapes.GetLogsCount) {
  try {
    const { data } = yield call(apiHelpers.getLogsCountHelper, payload.params);
    yield put(actions.putLogsCount(data));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    if (payload?.onError) payload.onError(error);
    console.error('getLogsCountWorker', error);
  }
}

export function* logsWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(logsTypes.GET_LOGS, getLogsWorker);
  yield takeLatest(logsTypes.GET_FILTER_DATA, getLogsFiltersWorker);
  yield takeLatest(logsTypes.GET_LOGS_COUNT, getLogsCountWorker);
}
