import React, { useMemo } from 'react';
import s from './SignalsTable.module.scss';
import {
  initialSignalValues,
  InitialSignalValuesType,
  MissedValues,
  SIGNAL_DATE,
  SIGNAL_FILTERS,
  SignalsFilterIDs,
  UnfilledValues
} from 'constants/signalsOrdersConstants';
import { filters } from '../../constants/logsConstants';

import { Button, Checkbox, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import { ButtonColorTypes } from 'constants/randomConstants';
import { GetSignalsQuery, Profiles } from 'interfaces/signalsOrdersShapes';
import { customizeFilter } from 'helpers/signalOrdersHelpers';
import SearchableSelect, { useStyles } from 'components/selects/searchableSelect/SearchableSelect';

interface SignalsFiltersProps {
  disabledFilter: boolean;
  profileId?: string;
  filterParams: typeof initialSignalValues;
  reqParams: GetSignalsQuery;
  profiles: Profiles[];
  pairs: Profiles[];
  setReqParams: (params: GetSignalsQuery) => void;
  setFilterParams: (params: InitialSignalValuesType) => void;
  setDisabledFilter: (shouldDisable: boolean) => void;
  setProfilesSearch: (search: string) => void;
  setPairsSearch: (search: string) => void;
}

const SignalsFilters: React.FC<SignalsFiltersProps> = ({
  filterParams,
  setReqParams,
  setFilterParams,
  disabledFilter,
  setDisabledFilter,
  reqParams,
  profiles,
  pairs,
  profileId,
  setProfilesSearch,
  setPairsSearch
}) => {
  const classes = useStyles();

  const defaultFilters = useMemo(() => SIGNAL_FILTERS(Boolean(profileId)), [profileId]);
  const allFilters = useMemo(
    () => customizeFilter(profiles, pairs, defaultFilters).filter((el) => !el.invisible),
    [defaultFilters, pairs, profiles]
  );

  const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof filterParams;
    const updatedFilterParams = {
      ...filterParams,
      ...(profileId && { profile: profileId }),
      page: 1,
      [name]: event.target.value
    };
    setFilterParams(updatedFilterParams);
    setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
  };
  const handleSearchChange = (id: string, searchText: string) => {
    if (id === filters.PAIR) {
      setPairsSearch(searchText);
    } else if (id === filters.PROFILE) {
      setProfilesSearch(searchText);
    }
  };

  return (
    <div className={s.filterWrapper}>
      <div>
        <div>
          {allFilters.map((filter) => (
            <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
              <InputLabel shrink htmlFor={filter.id} className={s.filterLabel}>
                {filter.label}
              </InputLabel>
              {filter.id === 'status' ? (
                <Select
                  id={filter.id}
                  className={classes.inputHeight}
                  name={filter.id}
                  displayEmpty
                  value={filterParams[filter.id]}
                  onChange={handleFilterChange}
                >
                  {filter.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <SearchableSelect
                  id={filter.id}
                  options={filter.options}
                  value={filterParams[filter.id]}
                  defaultValue={filter.options[0]}
                  onChange={handleFilterChange}
                  formControlClass={s.searchFilterWrapp}
                  onSearchChange={(searchText) => handleSearchChange(filter.id, searchText)}
                />
              )}
            </FormControl>
          ))}
        </div>
        <div>
          {SIGNAL_DATE.map((filter) => (
            <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
              <InputLabel shrink htmlFor={SignalsFilterIDs.AFTER_CREATING} className={s.filterLabel}>
                {filter.label}
              </InputLabel>
              <TextField
                onChange={handleFilterChange}
                value={filterParams[filter.value]}
                id="date"
                type="date"
                name={filter.fieldName}
                className={s.dataFilter}
              />
            </FormControl>
          ))}
        </div>
        <div>
          <FormControl className={s.filterLabel}>
            <InputLabel shrink htmlFor={SignalsFilterIDs.SHOW_MISSED} className={s.filterLabel}>
              Missed
            </InputLabel>
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.6)' }}
              value={filterParams[SignalsFilterIDs.SHOW_MISSED] !== MissedValues.MISSED}
              name={SignalsFilterIDs.SHOW_MISSED}
              onChange={handleFilterChange}
            />
          </FormControl>
          <FormControl className={s.filterLabel}>
            <InputLabel shrink htmlFor={SignalsFilterIDs.SHOW_UNFILLED} className={s.filterLabel}>
              Unfilled
            </InputLabel>
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.6)' }}
              value={filterParams[SignalsFilterIDs.SHOW_UNFILLED] !== UnfilledValues.UNFILLED}
              name={SignalsFilterIDs.SHOW_UNFILLED}
              onChange={handleFilterChange}
            />
          </FormControl>
        </div>
      </div>
      <Button
        fullWidth
        variant="outlined"
        size="medium"
        type="button"
        color={ButtonColorTypes.DEFAULT}
        classes={{ root: s.filterButton }}
        onClick={() => {
          setReqParams(filterParams);
          setDisabledFilter(true);
        }}
        disabled={disabledFilter}
      >
        Apply
      </Button>
    </div>
  );
};

export default SignalsFilters;
