import { SignalTimeframes } from 'constants/signalsOrdersConstants';
import { STRATEGY_SCHEMA_TYPE } from 'constants/strategyProfilesAddConstants';
import { STRATEGY_STATUS } from '../constants/strategyProfilesConstants';

export interface StrategyProfilesReducer {
  profile: null | StrategyProfileShape;
  profileTemplate: null | StrategyProfileShape;
  strategySettings: null | UpdateStrategyResponse;
  strategyProfilesData: StrategyProfilesData;
  status: null | SystemStatusShape;
  pairs: Array<PairsShape>;
  profiles: Array<{ id: number; title: string }>;
}

export interface SystemStatusShape {
  status: STRATEGY_STATUS.DISABLED | STRATEGY_STATUS.ACTIVE;
}

export interface AddSPErrors {
  mmProfilesErr: null | string;
  tradingPairsErr: null | string;
  profileErr: null | string;
  settingsErr: null | string;
  submitSummaryErr: null | string;
}

export interface ProfilesSettingsValues {
  title: string;
  mmProfile: string;
  tickers: string;
  template: string;
}

export interface IndicationSettingsValues {
  opp_tf: string;
  opp_sp: string;
  opp_atr: string;
  opp_enabled: boolean;
  le_tf: string;
  le_enabled: boolean;
  le_periods: number;
}

export interface CreateProfileReqData {
  title: string;
  tickers: number;
  mm_profile: number;
  status?: StrategyProfileStatuses;
  is_show?: boolean;
  template?: null | number;
}

export interface CreateProfile {
  type: string;
  payload: {
    reqData: CreateProfileReqData;
    onSuccess: () => void;
    onError: (err) => void;
  };
}

export interface UpdateProfile {
  type: string;
  payload: {
    id: number;
    reqData: CreateProfileReqData;
    onSuccess: () => void;
    onError: (err) => void;
  };
}

export interface SetProfile {
  type: string;
  payload: StrategyProfileShape;
}

export interface GetSystemStatus {
  type: string;
  payload: {
    onSuccess: () => void;
    onError: (err) => void;
  };
}

export interface SetSystemStatus {
  type: string;
  payload: {
    status: string;
    onSuccess: () => void;
    onError: (err) => void;
  };
}

export interface PutSystemStatus {
  type: string;
  payload: SystemStatusShape;
}

export enum AtrSmoothAlgoTypes {
  EMA = 'ema',
  RMA = 'rma',
  SMA = 'sma'
}

export enum AtrMinus1Types {
  LTE = 'lte',
  GTE = 'gte',
  RNG = 'range'
}

export enum PriceDirectionsTypes {
  GROW = 'grow_up',
  FALL = 'falling_down'
}

export interface KeyParametersShape {
  timeframe: SignalTimeframes;
  atr_n_periods: number;
  atr_smooth_algo: AtrSmoothAlgoTypes;
  atr_threshold_percents: number;
  price_direction: PriceDirectionsTypes;
  start_point_coefficient: number;
  timer_minutes: number;
  signal_duration_minutes: number;
  is_atr_minus_1: boolean;
  atr_minus_1_type: AtrMinus1Types;
  atr_minus_1_value: string;
  enable_sec_atr: boolean;
  revert_threshold: number;
  tr_in_atr_threshold: number;
}

export interface OrderManagementSettings {
  future_price_coefficient?: number;
  change_exit_timer_minutes?: number;
  change_exit_coefficient_by_time?: number;
  change_exit_d_threshold_by_distance?: number;
  change_exit_d_threshold2_by_distance?: number;
  change_exit_coefficient_by_distance?: number;
  change_exit_coefficient2_by_distance?: number;
  is_exit_rule_by_distance?: boolean | null;
  is_exit_rule_by_time?: boolean | null;
  is_exit_rule_by_time_and_distance?: boolean | null;

  enter_orders: Array<{
    atr_distance_coefficient: number;
    size_percent: number;
    timer_minutes: number;
  }>;

  exit_orders: {
    atr_timer: number;
    change_of_atr_percentage: number;
    exit_order_place_coefficient: number;
    size_timer_minutes: number;
    high_low_change_percentage: number;
    high_low_change_timer: number;
    is_exit_rule_by_time?: boolean;
    change_exit_coefficient_by_time?: number;
    change_exit_timer_minutes?: number;
    change_exit_d_threshold_by_distance?: number;
    change_exit_coefficient_by_distance?: number;
    change_exit_d_threshold2_by_distance?: number;
    change_exit_coefficient2_by_distance?: number;
  };
  exit_timer_coeff_settings?: Array<{
    change_exit_timer_minutes: number;
    change_exit_coefficient_by_time: number;
  }>;
  exit_d_threshold_settings?: Array<{
    change_exit_d_threshold_by_distance: number;
    change_exit_coefficient_by_distance: number;
  }>;
}

export interface StrategySettings {
  signal_settings: KeyParametersShape;
  order_management_settings: OrderManagementSettings;
  indications_settings: IndicationsSettings;
}

export interface StrategyProfileSettingsShape {
  title: string;
  template: null | string;
  mm_profile: {
    id: number;
    title: string;
    is_active: true;
    all_signals_quota: string;
    max_signals_amount: number;
    created_at: string;
    updated_at: string;
    exchange_account: number;
  };
  tickers: number;
  settings: {
    signal_settings: KeyParametersShape;
    order_management_settings: OrderManagementSettings;
    indications_settings: IndicationsSettings[];
    deviation_settings: DeviationSettings;
  };
}

interface LeSettings {
  timeframe: string;
  is_enabled: boolean;
  periods_amount: number;
}

interface OppSettings {
  opp_sp: number;
  timeframe: string;
  is_enabled: boolean;
  opp_atr_t_percent: number;
}

export interface IndicationsSettings {
  le_settings: LeSettings;
  opp_settings: OppSettings;
}

export interface DeviationSettings {
  limit_funding_rate: number;
  limit_price_deviation: number;
}

export interface DeviationSettingsValues {
  limit_funding_rate: number;
  limit_price_deviation: number;
}

export interface StrategyProfileShape {
  id: number;
  pairs: Array<StrategyPairShape>;
  tickers: number;
  ticker_configs_name: string;
  title: string;
  status: StrategyProfileStatuses;
  created_at: string;
  updated_at: string;
  settings: {
    signal_settings: KeyParametersShape;
    order_management_settings: OrderManagementSettings;
    indications_settings: IndicationsSettings[];
    deviation_settings: DeviationSettings;
  };
  mm_profile: {
    id: number;
    title: string;
    is_active: true;
    all_signals_quota: string;
    max_signals_amount: number;
    created_at: string;
    updated_at: string;
    exchange_account: number;
  };
  timeframe: SignalTimeframes;
  profile_direction: string;
  exchange_account: {
    api_key: string;
    api_secret: string;
    created_at: string;
    exchange: null;
    id: number;
    tg_notification_settings: Array<number>;
    title: string;
    updated_at: string;
  };
  is_show: boolean;
  template: null | string;
}

export interface UpdateStrategyData {
  title: string;
  schema_type: STRATEGY_SCHEMA_TYPE;
  settings: Partial<StrategySettings>;
}

export interface UpdateStrategyResponse {
  id: number;
  title: string;
  schema_type: STRATEGY_SCHEMA_TYPE;
  settings: Partial<StrategySettings>;
  created_at: string;
  updated_at: string;
}

export interface UpdateStrategyDetails {
  type: string;
  payload: {
    id: number;
    profileData: Partial<UpdateStrategyData>;
    onSuccess: () => void;
    onError: (err) => void;
  };
}

export interface SetStrategySettings {
  type: string;
  payload: UpdateStrategyResponse;
}

export interface GetStrategyProfilesReq {
  direction?: string;
  ordering?: string;
  page: number;
}

export interface GetStrategyProfiles {
  type: string;
  payload: { reqParams: GetStrategyProfilesReq; onFinally: () => void; onError: (err) => void };
}

export interface PutStrategyProfiles {
  type: string;
  payload: StrategyProfilesData;
}

export enum StrategyProfileStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  STOPPING = 'stopping',
  DRAFT = 'draft'
}

export interface StrategyPairShape {
  id: number;
  profile: number;
  pair: {
    id: number;
    ticker: string;
    is_active: boolean;
    is_deleted: boolean;
  };
}

export enum PairsStatusShape {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}
export interface PairsShape {
  id: number;
  pairs: number[];
  status: PairsStatusShape;
  originalStatus: PairsStatusShape;
  title: string;
  created_at: string;
  updated_at: string;
}

export interface SetStrategyProfilesShape {
  type: string;
  payload: StrategyProfilesShape[];
}

export interface StrategyProfilesShape {
  id: number;
  title: string;
}

export interface StrategyProfilesData {
  count: number;
  next: string;
  previous: string;
  results: Array<StrategyProfileShape>;
}

export enum PairsListFormValues {
  TITLE = 'title',
  PAIRS = 'pairs',
  STATUS = 'status'
}

export enum AllHideEnum {
  ALL = 'All',
  HIDE = 'Hide All'
}

export interface PairFormValues {
  title: string;
  pairs: number[];
  status: PairsStatusShape;
}

export interface StartStopStrategy {
  type: string;
  payload: { id: number; onError: (err) => void };
}

export interface PutUpdatedStrategyInList {
  type: string;
  payload: StrategyProfileShape;
}

export interface GetProfile {
  type: string;
  payload: {
    id: number;
    onSuccess: (data: StrategyProfileShape) => void;
    onError: (err) => void;
    isTemplateRequest: boolean;
  };
}

export interface GetStrategySettings {
  type: string;
  payload: { id: number; onError: (err) => void };
}

export interface UpdateStrategyVisibility {
  type: string;
  payload: { strategyId: number; reqData: { is_show: boolean }; onSuccess: () => void };
}

export interface GetStrategiesTickers {
  type: string;
  payload: { reqData; onSuccess: () => void; onError: (err) => void };
}

export interface SetStrategiesTickers {
  type: string;
  payload: PairsShape[];
}

export interface UpdatedStrategiesTicker {
  type: string;
  payload: PairsShape;
}

export interface UpdateStrategiesTickers {
  type: string;
  payload: { id: number; pairs: number[] };
}

export interface RemoveStrategyTickersSuccess {
  type: string;
  payload: { id: number };
}

export interface SaveRemoveStrategyTickers {
  type: string;
  payload: { id: number; onSuccess: () => void };
}

export interface AddRemoveStrategyTickers {
  type: string;
  payload: { values: PairFormValues; onSuccess: () => void };
}

export interface GetTemplateTickers {
  type: string;
  payload: { profile: string };
}

export interface AddRemoveStrategyTickersSuccess {
  type: string;
  payload: PairsShape;
}

export interface UpdateStatusStrategiesTickers {
  type: string;
  payload: { id: number; status: PairsStatusShape };
}
