import { ExchangeAccountShape } from 'interfaces/systemSettingsShapes';
import { changeDepositMMProfile } from '../redux/actions/mmProfilesActions';
import { MMReqParams } from '../constants/mmProfilesConstants';
import { FilterData, LogsShape } from './logsShapes';

export interface AddMMProfileReqShape {
  title: string;
  status?: MMStatuses;
  all_signals_quota: string;
  max_signals_amount: number;
  exchange_account: number;
}

interface MMProfileShape {
  id: number;
  title: string;
  status?: MMStatuses;
  created_at: Date;
  updated_at: Date;
  settings: number;
  mm_profile: number;
}

export interface MMExtendedShape {
  id: number;
  profiles: Array<MMProfileShape>;
  exchange_account: ExchangeAccountShape;
  title: string;
  status: MMStatuses;
  all_signals_quota: string;
  max_signals_amount: number;
  max_factor_amount: number;
  created_at: Date;
  updated_at: Date;
  is_show: boolean;
  profile_direction: string;
}

export interface ChangedDepositFormDTO {
  operation_type: 'increase' | 'decrease';
  size: number;
}

export interface ChangedAutoDepositFormDTO {
  is_auto_change_mm_depo_enabled: boolean;
  auto_change_mm_depo_percent: string;
}

export interface FormValues {
  operation_type: string;
  size: string;
}

export enum MMStatuses {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  STOPPING = 'stopping'
}

export interface AutoChangeDepo {
  auto_change_mm_depo_percent: number;
  is_auto_change_mm_depo_enabled: boolean;
}

export interface PaginatedMMProfiles {
  count: number;
  next: string;
  previous: string;
  results: MMExtendedShape[];
  summary: {
    sum_all_signals_quota: number;
    sum_one_signal_quota: number;
  };
}

export interface DepositInfo {
  ideo_r_long: number;
  ideo_r_short: number;
  mm_asset_sum: number;
  mm_deposit: number;
}

export interface MMProfilesReducer {
  mmProfiles: PaginatedMMProfiles;
  autoDepositDepo: AutoChangeDepo;
  depositInfo: DepositInfo;
}

export interface AddMMProfileShape {
  type: string;
  payload: { reqData: AddMMProfileReqShape; onSuccess: () => void; onError: (err) => void };
}

export interface GetMMProfilesShape {
  type: string;
  payload: { reqParams: MMReqParams; onSuccess: () => void; onError: (err) => void };
}

export interface GetMMProfilesFilterShape {
  type: string;
  payload: { reqParams: MMReqParams & { searchMM: string }; onSuccess: (data) => void; onError: (err) => void };
}

export interface PutMMProfiles {
  type: string;
  payload: PaginatedMMProfiles;
}

export interface PutMMProfile {
  type: string;
  payload: MMExtendedShape[];
}

export interface ChangeMMProfileReqShape {
  title?: string;
  status?: MMStatuses;
  all_signals_quota?: string;
  max_signals_amount?: number;
  exchange_account?: number;
  max_factor_amount?: number;
  is_show?: boolean;
}

export interface ChangeMMProfile {
  type: string;
  payload: {
    profileId: number;
    reqData: ChangeMMProfileReqShape;
    onError: (err) => void;
    onSuccess?: () => void;
  };
}

export interface ChangeMMProfileSuccess {
  type: string;
  payload: MMExtendedShape;
}

export interface GetMMProfile {
  type: string;
  payload: {
    id: number;
    onError: () => void;
  };
}

export interface ChangeMMProfileVisibility {
  type: string;
  payload: {
    profileId: number;
    reqData: ChangeMMProfileReqShape;
    onSuccess: () => void;
  };
}

export interface ChangeDepositMMProfile {
  type: string;
  payload: {
    reqData: ChangedDepositFormDTO;
    onSuccess: () => void;
    onError: (err) => void;
  };
}

export interface GetAutoDepositMMProfile {
  type: string;
  payload: {
    onSuccess: () => void;
    onError: (err) => void;
  };
}

export interface GetDepositInfoMMProfile {
  type: string;
  payload: {
    onError: (err) => void;
  };
}

export interface PutDepositInfoMMProfile {
  type: string;
  payload: DepositInfo;
}

export interface SaveAutoDepositMMProfile {
  type: string;
  payload: AutoChangeDepo;
}

export interface ChangeAutoDepositMMProfile {
  type: string;
  payload: {
    reqData: ChangedAutoDepositFormDTO;
    onSuccess: () => void;
    onError: (err) => void;
  };
}
