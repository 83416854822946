import React, { FC } from 'react';
import s from '../LogsTable.module.scss';
import { Button, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import { filterIDs, initialFilterValues, LOGS_FILTER } from '../../../constants/logsConstants';
import { ButtonColorTypes } from '../../../constants/randomConstants';
import SearchableSelect, { useStyles } from 'components/selects/searchableSelect/SearchableSelect';

interface LogsFilterProps {
  logsFilter: typeof LOGS_FILTER;
  filterParams: typeof initialFilterValues;
  handleFilterChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  setReqParams: (filters: typeof initialFilterValues) => void;
  setDisabledFilter: (isDisabled: boolean) => void;
  disabledFilter: boolean;
  setProfilesSearch: (search: string) => void;
  setPairsSearch: (search: string) => void;
}

export const LogsFilter: FC<LogsFilterProps> = ({
  logsFilter,
  filterParams,
  handleFilterChange,
  setReqParams,
  setDisabledFilter,
  disabledFilter,
  setProfilesSearch,
  setPairsSearch
}): JSX.Element => {
  const classes = useStyles();

  const handleSearchChange = (id: string, searchText: string) => {
    if (id === filterIDs.PAIR) {
      setPairsSearch(searchText);
    } else if (id === filterIDs.PROFILE) {
      setProfilesSearch(searchText);
    }
  };

  return (
    <div className={s.filterWrapper}>
      {logsFilter.map(({ id, label, options }) => (
        <FormControl key={id} variant="outlined" className={s.filterFormControl}>
          <InputLabel shrink htmlFor={id} className={s.filterLabel}>
            {label}
          </InputLabel>
          {id === filterIDs.TYPE ? (
            <Select
              id={id}
              className={classes.inputHeight}
              name={id}
              displayEmpty
              value={filterParams[id]}
              onChange={handleFilterChange}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <SearchableSelect
              id={id}
              options={options}
              value={filterParams[id]}
              onChange={handleFilterChange}
              formControlClass={s.searchFilterWrapp}
              onSearchChange={(searchText) => handleSearchChange(id, searchText)}
            />
          )}
        </FormControl>
      ))}
      <FormControl variant="outlined" className={s.filterFormControl}>
        <InputLabel shrink htmlFor={filterIDs.SIGNAL} className={s.filterLabel}>
          Signal
        </InputLabel>
        <TextField
          variant="outlined"
          id={filterIDs.SIGNAL}
          name={filterIDs.SIGNAL}
          value={filterParams[filterIDs.SIGNAL]}
          type="number"
          onChange={handleFilterChange}
          className={classes.inputHeight}
        />
      </FormControl>

      <Button
        fullWidth
        variant="outlined"
        size="medium"
        type="button"
        color={ButtonColorTypes.DEFAULT}
        classes={{ root: s.filterButton }}
        onClick={() => {
          setReqParams(filterParams);
          setDisabledFilter(true);
        }}
        disabled={disabledFilter}
      >
        Apply
      </Button>
    </div>
  );
};
