import * as Shapes from '../../../interfaces/logsShapes';
import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from '../../../constants/apiEndpoints';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';

export const getLogsHelper = (params: Partial<Shapes.GetLogsQuery>): Promise<AxiosResponse<Shapes.LogsData>> => {
  return axios.get(API_ENDPOINTS.LOGS, { params });
};

export const getLogsFiltersHelper = (filter?: string): Promise<AxiosResponse<Shapes.LogsData>> => {
  return axios.get(API_ENDPOINTS.LOGS_FILTERS(filter));
};

export const getLogsCountHelper = (params: Partial<Shapes.GetLogsQuery>): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.LOGS_COUNT, { params });
};
