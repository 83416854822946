import { ColumnAlignOptions } from './randomConstants';
import { LogsColIds as LogsColPrivilegesIds } from './privilegesConstants';

export enum LogsColIds {
  ID = 'id',
  DATE = 'date',
  TYPE = 'type',
  PAIR_TICKER = 'pair_ticker',
  PROFILE_NAME = 'profile_name',
  SIGNAL_ID = 'signal_id',
  ORDER_ID = 'order_id',
  LOG_DATA = 'log_data'
}

export const LOGS_TABLE_COL_NAMES = {
  [LogsColIds.ID]: 'ID',
  [LogsColIds.DATE]: 'Date & time',
  [LogsColIds.TYPE]: 'Event',
  [LogsColIds.PAIR_TICKER]: 'Pair',
  [LogsColIds.PROFILE_NAME]: 'Profile',
  [LogsColIds.SIGNAL_ID]: 'Signal',
  [LogsColIds.ORDER_ID]: 'Order',
  [LogsColIds.LOG_DATA]: 'Message'
};

export const SERVER_CLIENT_COLS_MAP = {
  [LogsColIds.ID]: LogsColPrivilegesIds.ID,
  [LogsColIds.DATE]: LogsColPrivilegesIds.DATE,
  [LogsColIds.TYPE]: LogsColPrivilegesIds.TYPE,
  [LogsColIds.PAIR_TICKER]: LogsColPrivilegesIds.PAIR_ID,
  [LogsColIds.PROFILE_NAME]: LogsColPrivilegesIds.PROFILE_TITLE,
  [LogsColIds.SIGNAL_ID]: LogsColPrivilegesIds.SIGNAL_ID,
  [LogsColIds.ORDER_ID]: LogsColPrivilegesIds.ORDER_ID,
  [LogsColIds.LOG_DATA]: LogsColPrivilegesIds.LOG_DATA
};

export const LOGS_COLUMNS = [
  {
    id: LogsColIds.ID,
    title: LOGS_TABLE_COL_NAMES[LogsColIds.ID],
    align: ColumnAlignOptions.CENTER,
    width: '60px'
  },
  {
    id: LogsColIds.DATE,
    title: LOGS_TABLE_COL_NAMES[LogsColIds.DATE],
    align: ColumnAlignOptions.CENTER,
    width: '100px'
  },
  {
    id: LogsColIds.TYPE,
    title: LOGS_TABLE_COL_NAMES[LogsColIds.TYPE],
    align: ColumnAlignOptions.LEFT,
    width: '120px'
  },
  {
    id: LogsColIds.PAIR_TICKER,
    title: LOGS_TABLE_COL_NAMES[LogsColIds.PAIR_TICKER],
    align: ColumnAlignOptions.CENTER,
    width: '80px'
  },
  {
    id: LogsColIds.PROFILE_NAME,
    title: LOGS_TABLE_COL_NAMES[LogsColIds.PROFILE_NAME],
    align: ColumnAlignOptions.CENTER,
    width: '80px'
  },
  {
    id: LogsColIds.SIGNAL_ID,
    title: LOGS_TABLE_COL_NAMES[LogsColIds.SIGNAL_ID],
    align: ColumnAlignOptions.CENTER,
    width: '80px'
  },
  {
    id: LogsColIds.ORDER_ID,
    title: LOGS_TABLE_COL_NAMES[LogsColIds.ORDER_ID],
    align: ColumnAlignOptions.CENTER,
    width: '80px'
  },
  {
    id: LogsColIds.LOG_DATA,
    title: LOGS_TABLE_COL_NAMES[LogsColIds.LOG_DATA],
    align: ColumnAlignOptions.LEFT,
    width: 'auto'
  }
];

export enum filterIDsFromRequest {
  PAIR = 'pairs_ids',
  PROFILE = 'profiles_ids'
}

export enum filterIDs {
  PER_PAGE = 'page_size',
  TYPE = 'type',
  PAIR = 'pair_id',
  PROFILE = 'profile_id',
  SIGNAL = 'signal_id'
}

export enum filters {
  TYPE = 'type',
  PAIR = 'pair',
  PROFILE = 'profile',
  SIGNAL = 'signal'
}

export const filterLabels = {
  [filterIDs.PER_PAGE]: 'Per page',
  [filterIDs.TYPE]: 'Type',
  [filterIDs.PAIR]: 'Pair',
  [filterIDs.PROFILE]: 'Profile'
};

export const filterPerPageValues = {
  PP_50: '50',
  PP_100: '100',
  PP_200: '200',
  PP_500: '500'
};

export const filterPerPageLabels = {
  [filterPerPageValues.PP_50]: '50',
  [filterPerPageValues.PP_100]: '100',
  [filterPerPageValues.PP_200]: '200',
  [filterPerPageValues.PP_500]: '500'
};

export const filterTypeValues = {
  NONE: '',
  SYSTEM: 'system',
  GENERAL: 'general',
  ERROR: 'error',
  USER: 'user',
  RESTRICTIONS: 'restrictions',
  DIVERGENCE: 'divergence'
};

export const filterTypeLabels = {
  [filterTypeValues.NONE]: 'All',
  [filterTypeValues.SYSTEM]: 'System',
  [filterTypeValues.GENERAL]: 'General',
  [filterTypeValues.ERROR]: 'Error',
  [filterTypeValues.USER]: 'User',
  [filterTypeValues.RESTRICTIONS]: 'Restrictions',
  [filterTypeValues.DIVERGENCE]: 'Divergence'
};

export const filterPairValues = {
  NONE: ''
};

export const filterPairLabels = {
  [filterTypeValues.NONE]: 'All'
};

export const filterProfileValues = {
  NONE: ''
};

export const filterProfileLabels = {
  [filterProfileValues.NONE]: 'All'
};

export const LOGS_FILTER = [
  {
    id: filterIDs.TYPE,
    label: filterLabels[filterIDs.TYPE],
    options: [
      { value: filterTypeValues.NONE, label: filterTypeLabels[filterTypeValues.NONE] },
      { value: filterTypeValues.SYSTEM, label: filterTypeLabels[filterTypeValues.SYSTEM] },
      { value: filterTypeValues.GENERAL, label: filterTypeLabels[filterTypeValues.GENERAL] },
      { value: filterTypeValues.ERROR, label: filterTypeLabels[filterTypeValues.ERROR] },
      { value: filterTypeValues.USER, label: filterTypeLabels[filterTypeValues.USER] },
      { value: filterTypeValues.RESTRICTIONS, label: filterTypeLabels[filterTypeValues.RESTRICTIONS] },
      { value: filterTypeValues.DIVERGENCE, label: filterTypeLabels[filterTypeValues.DIVERGENCE] }
    ]
  },
  {
    id: filterIDs.PAIR,
    label: filterLabels[filterIDs.PAIR],
    options: [{ value: filterPairValues.NONE, label: filterPairLabels[filterPairValues.NONE] }]
  },
  {
    id: filterIDs.PROFILE,
    label: filterLabels[filterIDs.PROFILE],
    options: [{ value: filterProfileValues.NONE, label: filterProfileLabels[filterProfileValues.NONE] }]
  }
];

export const initialFilterValues = {
  page: 1,
  [filterIDs.PER_PAGE]: filterPerPageValues.PP_500,
  [filterIDs.TYPE]: filterTypeValues.NONE,
  [filterIDs.PAIR]: filterPairValues.NONE,
  [filterIDs.PROFILE]: filterProfileValues.NONE,
  [filterIDs.SIGNAL]: ''
};
